





























































































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import router from '@/router';
import { db } from '@/main';
import { User } from '@/models/user.model';

export default Vue.extend({
  data() {
    return {
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      email: '',
      emailForReset: '',
      showResetEmailDialog: false,
      isEmailResetDialogFormValid: false,
      password: '',
      showPassword: false,
      isBusy: false,
      rules: {
        min: (value: string) => value.length >= 6 || 'Min 6 characters',
        email: (value: string) =>
          /.+@.+\..+/.test(value) || 'E-mail must be valid',
        password: (v: string) => !!v || 'Password is required'
      }
    };
  },
  methods: {
    signIn() {
      this.isBusy = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(user => {
          this.$store.commit('updateFirebaseUser', user);
          const authListener = firebase
            .auth()
            .onAuthStateChanged((user: any) => {
              if (user) {
                db.collection('users')
                  .doc(user.uid)
                  .get()
                  .then((doc: firebase.firestore.DocumentData) => {
                    this.$store.commit('updateCurrentUser', doc.data() as User);
                    this.isBusy = false;
                    router.push({ name: 'Home' });
                  });
                authListener();
              }
            });
        })
        .catch(error => {
          this.isBusy = false;
          this.showSnackbar(error, 'error');
        });
    },
    resetPassword() {
      this.isBusy = true;
      const auth = firebase.auth();
      auth
        .sendPasswordResetEmail(this.emailForReset)
        .then(() => {
          this.isBusy = false;
          this.showSnackbar(
            'Please check your email for a reset link.',
            'success'
          );
          this.showResetEmailDialog = false;
        })
        .catch(error => {
          this.showSnackbar(error, 'error');
          this.showResetEmailDialog = false;
          this.isBusy = false;
        });
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  }
});
